<template>
  <div class="Ach">
    <div class="Achievement">
      <div class="Achievement_top">
        <i class="el-icon-s-home"></i>
        >{{name}}>投诉
      </div>
      <div class="Achievement_center">
        <!-- 标题 -->
        <div class="center_top huanghang">投诉</div>
        <!-- 投诉详情 -->
        <div class="con_tent">
          <div>
            <div class="fonsize">被投诉信息：</div>
            <span>
              <el-table :data="tableData" style="width: 85%;margin:auto;border:1px solid #000000;">
                <el-table-column label="投诉">
                  <el-table-column prop="childTaskName" label="子任务名称"></el-table-column>
                  <el-table-column prop="fzrName" label="加工者昵称"></el-table-column>
                  <el-table-column prop="childTaskDescribe" label="描述"></el-table-column>
                  <el-table-column prop="childTaskMoney" label="酬劳"></el-table-column>
                </el-table-column>
              </el-table>
            </span>
          </div>
          <div class="mar_top" style="text-align:right;width:97.5%;">
            <span @click="close" class="askhelp-icon"></span>
          </div>
          <div>
            <span class="fonsize col yuanyins">
              <span style="color:red;font-weight:bold ;">*</span>原因：
            </span>
            <span>
              <textarea style="resize: none;font-size:14px;padding:2%;" v-model="textarea"></textarea>
            </span>
          </div>
          <div class="mar_top">
            <span class="fonsize col">上传图片：</span>
            <span class="borders">
              <el-upload
                :action="surl"
                accept=".jpg, .jpeg, .png, .gif, .bmp, .pdf, .JPG, .JPEG, .PBG"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="PICsuccess"
                class="Imege"
              >
                <i class="up-img"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img :src="dialogImageUrl" width="100%" alt class="width_height" />
              </el-dialog>
            </span>
          </div>
          <div class="mar_top mar_center">
            <el-button type="primary" @click="submit()">提交</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="tankuang" v-if="guize">
      <div class="shensu">
        <div class="heyue_top">
          <span class="yin1" @click="close"></span>
        </div>
        <!-- 查看规则 -->
        <div class="yuanyin">原因填写提示</div>
        <div>
          <ul>
            <li>1.因内容 与发布的任务描述不符合。</li>
            <li>2.妹入或创作错误率高。</li>
            <li>3.未按照规定时间完成。</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dateType, showTimeFormat } from "@/js/common/index.js";
import { QUERYED, UPDATE, UPDATES, INSERT } from "@/services/dao";
export default {
  data() {
    return {
      name: this.$route.query.childTaskName, ///任务名称
      textarea: "",
      tableData: [],
      surl: "", //路径
      fileList1: [],
      dialogVisible: false,
      dialogImageUrl: "", //封面图片
      guize: false //规则
    };
  },
  created() {
    this.surl = this.api.LoginURL.concat("/resources/trading/resourcesUpload");
    this.init(); //初始化
  },
  methods: {
    async init() {
      let info = await QUERYED(
        "POST",
        "",
        "jgChildTask(where: {id : {_eq: " +
          this.$route.query.id +
          "}}) {id   childTaskName childTaskDescribe childTaskMoney  fzrName  }"
      );
      this.tableData = info.data.jgChildTask;
    },
    //关闭弹框
    close() {
      this.guize = !this.guize; //关闭
    },
    //上传图片成功后返回的数据（图片）
    PICsuccess(file, fileList) {
      this.dialogImageUrl = file.data.url;
      console.log("上传返回的图片路径：" + this.dialogImageUrl);
      console.log("fileList:", fileList);
      this.fileList1.push(fileList);
      console.log("this.fileList1", this.fileList1);
      // this.fileList1[0]=fileList;
      //  console.log("数组：",this.fileList1);
      // this.fileList1[0].url= this.api.LoginURL.concat(file.data.url);
    },
    handleRemove(file, fileList) {
      // console.log(file.response.data.url)
      let arr = [];
      for (let i = 0; i < this.fileList1.length; i++) {
        if (this.fileList1[i].raw.uid !== file.uid) {
          arr.push(this.fileList1[i]);
        }
      }
      this.fileList1 = arr;
      console.log("上传路径：", this.fileList1);
    },
    //点击文件列表中已上传的文件时的钩子（图片）
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 提交
    async submit() {
      // 有三种方式获取
      // var time1 = new Date().getTime();///当前时间的时间戳
      if (this.textarea == "") {
        this.$message({
          message: "请填写投诉原因！",
          type: "warning"
        });
      } else {
        let image = []; ///上传图片数组
        for (let i = 0; i < this.fileList1.length; i++) {
          image.push(this.fileList1[i].response.data.url);
        }
        // alert(JSON.stringify(image).replaceAll("\"","\'"))
        if (this.$store.state.userinfo.id == undefined) {
          this.$message({
            message: "请您先登录！",
            type: "warning"
          });
        } else {
          ////判断该用户对该子任务是否有投诉，且为待审核状态
          // let info=await QUERYED("POST","","")

          // ).replaceAll("\"","\'")
          let datas = await INSERT(
            "post",
            "",
            " insert_jgComplain(objects: {jgChildTaskId : " +
              this.$route.query.id +
              ",userid : " +
              this.$store.state.userinfo.id +
              ",fzrId : " +
              this.$route.query.fzrId +
              "jgChildfzrId: " +
              this.$route.query.jgUserId +
              ',complaintsReasons  : "' +
              this.textarea +
              '",complaintImg: ' +
              JSON.stringify(JSON.stringify(image)) +
              ',creatime: "' +
              new Date().getTime() +
              '"}) { affected_rows  }'
          );
          if (datas.data.insert_jgComplain.affected_rows == 1) {
            /////给加工者和负责人发送一条被投诉信息
            /////加工者
            let jgz = await INSERT(
              "post",
              "",
              " insert_jgMessage(objects: {messageType: 4,userId : " +
                this.$route.query.jgUserId +
                ",beuserid : " +
                this.$store.state.userinfo.id +
                ",userType: " +
                3 +
                ',taskName: "' +
                this.tableData[0].childTaskName +
                '",bename: "' +
                this.$store.state.userinfo.name +
                '",taskTime : "' +
                new Date().getTime() +
                '",reason: "' +
                this.textarea +
                '"}) { affected_rows  }'
            );
            if (jgz.data.insert_jgMessage.affected_rows == 1) {
              /////负责人
              let fzr = await INSERT(
                "post",
                "",
                " insert_jgMessage(objects: {messageType: 4,userId : " +
                  this.$route.query.fzrId +
                  ",beuserid : " +
                  this.$store.state.userinfo.id +
                  ",userType: " +
                  2 +
                  ',taskName: "' +
                  this.tableData[0].childTaskName +
                  '",bename: "' +
                  this.$store.state.userinfo.name +
                  '",taskTime : "' +
                  new Date().getTime() +
                  '",reason: "' +
                  this.textarea +
                  '"}) { affected_rows  }'
              );
              if (fzr.data.insert_jgMessage.affected_rows) {
                this.$message({
                  message: "投诉成功！",
                  type: "success"
                });
                (this.fileList1 = []),
                  (this.textarea = ""),
                  (this.dialogImageUrl = "");
                window.location.reload();
              }
            }
          }
        }
      }
    }
  }
};
</script>
<style scoped>
/* 数字字符串换行 */
.huanghang {
  word-wrap: break-word;
  word-break: normal;
  overflow: hidden;
  /* width: 100%; */
}
.Ach {
  width: 100%;
  background-color: #eeeeee;
}
.Achievement {
  width: 80%;
  /* border: 1px solid red; */
  /* display: inline-block; */
  margin: auto;
}
.Achievement_center {
  background-color: #ffffff;
}
.el-icon-s-home {
  font-size: 25px;
  color: #a5a5a5;
}
.Achievement_top {
  height: 66px;
  line-height: 66px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.center_top {
  width: 100%;
  height: 50px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  line-height: 50px;
  text-align: center;
  border-bottom: 2px solid #b5b5b5;
  display: inline-block;
  padding-top: 20px;
  padding-bottom: 10px;
}
.border {
  border-bottom: 1px solid #b5b5b5;
  border-left: 1px solid #b5b5b5;
  border-right: 1px solid #b5b5b5;
  width: 90%;
  margin: auto;
}
/* 表格边框 */
/* .el-table thead.is-group th{
  background-color:red ;
} */
.fonsize {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 55px;
}
.con_tent {
  /* border: 1px solid red; */
  padding: 6% 6% 6% 6%;
}
.mar_top {
  /* border: 1px solid red; */
  margin-top: 30px;
}
.mar_top > span {
  vertical-align: top;
}
.borders {
  /* padding-top:10px; */
  padding: 0;
  border: 1px solid #000000;
  margin: 0;
  width: 90%;
  /* height: 150px; */
  display: inline-block;
}
textarea {
  padding: 0;
  border: 1px solid #000000;
  margin: 0;
  width: 86%;
  height: 100px;
  font-size: 16px;
  color: rgb(56, 56, 56);
  line-height: 15px;
}
textarea:focus {
  outline: none;
  /* border: 1px solid #9ac1f5; */
  /* box-shadow: 1px 2px 13px 0px rgba(11, 97, 226, 0.44); */
  /* border-width :0 0 1px 0 ; */
}
.col {
  width: 70px;
  text-align: right;
  display: inline-block;
  /* border: 1px solid blue; */
}
.Imege {
  display: inline-block;
  /* width: 3rem; */
  /* height: 1.1rem; */
  position: relative;
  /* left: 10px; */
  /* border: 1px solid red; */
}
.up-img {
  margin-left: 10px;
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border: 1px solid rgba(210, 210, 210, 1);
  border-radius: 3px;
  background-image: url(~@/assets/icon/add-img.png);
  background-size: 25px 25px;
  background-repeat: no-repeat;
  background-position-x: 21px;
  background-position-y: 20px;
  position: relative;
  left: -30px;
  top: -10px;
  background-color: white;
  cursor: pointer;
  /* border: 1px solid red; */
}
.mar_center {
  margin-top: 60px;
  text-align: center;
}
.askhelp-icon {
  display: inline-block;
  height: 13px;
  width: 13px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url("~@/assets/icon/help.png");
  background-size: 100% 100%;
}
/* 弹框 */
.tankuang {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shensu {
  width: 445px;
  height: 236px;
  background-color: #fdfdfd;
  border: 1px solid #2e2e2e;
  /* position: relative; */
  /* z-index: -1; */
}
.heyue_top {
  width: 100%;
  /* height: 18px; */
  padding: 10px 0px 0px 0px;
  /* border: 1px solid red; */
  display: inline-block;
  text-align: right;
} /* 引用 */
.yin1 {
  display: inline-block;
  background-image: url("~@/assets/quxiao.png");
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-size: 25px 25px;
  /* border: 1px solid #dcdcdc; */
  border-radius: 5%;
  cursor: pointer;
  margin-right: 10px;
}
.yuanyin {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #6d6d6d;
  line-height: 24px;
  width: 100%;
  text-align: center;
}
ul {
  /* width: 100%; */
  /* text-align: center; */
  padding-left: 110px;
}
ul > li {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #6d6d6d;
  line-height: 35px;
  /* display: inline-block; */
  width: 230px;
  /* border: 1px solid red; */
}
.yuanyins {
  position: relative;
  top: -110px;
}
.el-table::before {
  z-index: auto;
  /* background-color: red; */
}
</style>